.index-page {
  #show-hide-intro {
    font-size: 1.6rem;
  }

  .info {
    padding: 10px 0 10px 0;
    @include viewport600Up{
      padding: 30px 0 20px 0;
    }
    @include viewport1200Up{
      padding: 30px 0 20px 0;
    }

  }
  .intro {
    max-width: $DW_article;
    margin: 0 auto;
    height: 0;
    overflow-y: hidden;
    margin-top: 1rem;
    padding: 0 1rem;

    @include viewport600Up{
      padding: 0 2rem;
    }
    @include viewport1200Up{
      padding: 0 1rem;
    }

    p,
    a {
      font-size: 1.6rem;
      line-height: 3.1rem;
    }
  }

  .name {
    padding-top: 5rem;
    font-family: Lato, SansSerif;
    font-weight: 400;
    font-size: 4.5rem;
    letter-spacing: 0.1rem;
  }

  .subtitle {
    font-family: Lato, SansSerif;
    font-weight: 300;
    color: #707070;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-top: 1rem;
  }

  .post-wrapper .post {
    margin-top: 4rem;
    margin-bottom: 4rem;
    .image,
    .text {
      display: inline-block;
    }

    .image {
      width: 100%;
      height: 0;
      padding-top: 55%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
      }
    }

    .text {
      width: calc(100% - 3rem);
      vertical-align: top;
      padding: 2rem 1rem 4rem 3rem;
      
      .title strong {
        padding-top: 3rem;
        font-size: 3rem;
        line-height: 3.5rem;
      }
      p {
        @include multiLineEllipsis($lineHeight: 3.2rem, $lineCount: 3, $bgColor: white);
      }
    }
  }

  span.title {
    position: relative;
  }

  span.title::after {
    position: absolute;
    left: 0;
    bottom: -8px;
    content: "";
    width: 10px;
    height: 4px;
    background: linear-gradient(270deg, #f53e15, #f5972b, #f5cf36, #16c17c, #3274e7, #aa37f6, #d800b3);
    background-size: 1400% 1400%;
    animation: rainbowColors 10s ease infinite;
    transition: 0.35s;
  }

  .post-wrapper .post:hover span.title::after {
    width: 100%;
  }

  @keyframes rainbowColors {
    0% {
      background-position: 0% 51%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 51%;
    }
  }
}
