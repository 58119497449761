$iphone-overlay-width_3x: 1311;
$iphone-overlay-height_3x: 2646;
$iphone-media-width_3x: 1170;
$iphone-media-height_3x: 2532;
$iphone-media-margin-left_3x: 24 * 3;
$iphone-media-margin-top_3x: 19 * 3;

.iphone-13-pro-flexbox-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 2rem 0 ;
}

.iphone-13-pro-flexbox-wrapper.one{
    .iphone-13-pro-wrapper{
        max-width: $DW_article;
        @include viewportArticleWidthUp{
            max-width: $DW_article;
        }
    }
}

.iphone-13-pro-flexbox-wrapper.two{
    .iphone-13-pro-wrapper{
        max-width: calc(#{$DW_article} / 2.2);
    }
}

.iphone-13-pro-flexbox-wrapper.three{
    .iphone-13-pro-wrapper{
        max-width: calc(#{$DW_article} / 3.2 );
    }
}

.iphone-13-pro-wrapper{
    position: relative;
    width: 100%;
    min-width: 200px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    aspect-ratio: calc( #{$iphone-overlay-width_3x} / #{$iphone-overlay-height_3x});

    .overlay{
        aspect-ratio: calc( #{$iphone-overlay-width_3x} / #{$iphone-overlay-height_3x});
        width: 100%;
        background-image: url("/assets/misc/iphone-13-pro-sierra-blue-frame.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
    }

    .media-padding{
        box-sizing: border-box;
        width: calc(100% * #{$iphone-media-width_3x} / #{$iphone-overlay-width_3x});
        position:absolute;
        margin-left: calc(100% * #{$iphone-media-margin-left_3x} /  #{$iphone-overlay-width_3x});
        margin-top: calc(100% * #{$iphone-media-margin-top_3x} / #{$iphone-overlay-width_3x});

        img, video{
            width: 100%;
        }
    }
}