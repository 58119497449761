@mixin figureText($textAlign) {
  z-index: 1;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  color: grey;
  line-height: 1.8rem;
  text-align: $textAlign;
  font-family: $fontFamily;
}

body.post {
  // Single Images and Album Styles
    .single-image:not(.no-zoom)::before {
    content: "Click or tap the image below for fullscreen mode";
    @include figureText(center);
    display:block;
    width: 100%;
  }

  .album::before {
    content: "Click or tap any image in the album below for fullscreen mode";
    @include figureText(center);
    display:block;
    width: 100%;
  }

  .single-image:not(.no-zoom) img:hover, .album img:hover{
    cursor: pointer;
  }

  .single-image,
  .album {

    margin-top: 2rem;
    figcaption,
    figcaption a {
      @include figureText(left);
      text-align: left;
    }

    figcaption {
      margin-top: 1rem;
      padding: 0;
      @include multiLineEllipsis($lineHeight: 2rem, $lineCount: 3, $bgColor: white);
    }
  }

  .single-image {
    text-align: center;

    & > p,
    & > p picture {
      margin: 0;
    }

    img {
      width: auto;
      max-width: 100%;
    }
  }

  .album {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @mixin square-thumbnails-formatter($size) {
    width: $size;
    min-height: $size;
    margin-bottom: 2rem;

    figure {
      width: $size;
      min-height: $size;

      picture {
        display: block;
        position: relative;
        width: $size;
        height: $size;

        img {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          object-position: center center;
          object-fit: cover;
        }
      }
    }
  }

  .album.square .figure-wrapper {
    
    @include square-thumbnails-formatter(40vw);
    
    @include viewportArticleWidthUp() {
      @include square-thumbnails-formatter(calc(64rem / 2));
    }
  }

  #lightbox2 {
    $lightboxAnimationTime: 0.4s;

    // Fill up entire screen without intercepting interaction
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0;
    pointer-events: none;
    z-index: 15;
    transition: $lightboxAnimationTime ease-in;

    .icon {
      display: none;
      z-index: 30;
      color: grey;
      font-size: 20px;
      @include viewport600Up{
        font-size: 30px;
      }
      @include viewport1200Up{
        font-size: 40px;
      }
    }

    .chevron-wrapper {
      text-align: center;
      position: fixed;
      width: 20px;
      height: 20px;

      @include viewport600Up {
        width: 30px;
        height: 30px;
      }

      @include viewport1200Up {
        width: 40px;
        height: 40px;
      }

      top: calc(100vh / 2 - 20px);

      &.right {
        top: calc(100vh / 2 - 5px);
        right: 5px;
        @include viewport600Up{
          top: calc(100vh / 2 - 10px);
          right: 10px;
        }
        @include viewport1200Up{
          right:20px;
        }
        
      }

      &.left {
        top: calc(100vh / 2 - 5px);
        left: 5px;
        left: 20px;
        @include viewport600Up {
          top: calc(100vh / 2 - 10px);
          left: 10px;
        }
        @include viewport1200Up{
          left:20px;
        }
      }
    }

    .icon:hover,
    .chevron-wrapper {
      cursor: pointer;
    }

    .chevron-wrapper,
    .close.icon,
    #lightbox2-caption {
      display: none;
      z-index: 1000;
    }

    .chevron-wrapper.hide {
      display: none;
    }

    .close.icon {
      position: fixed;
      top: 5px;
      left:5px;

      @include viewport600Up {
        left: 10px;
        top: 10px;
      }

      @include viewport1200Up {
        left: 10px;
        top: 10px;
      }
    }

    #lightbox2-thumbnail {
      z-index: 20;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    #lightbox2-thumbnailWrapper.transition{
      transition: $lightboxAnimationTime ease-out;
    }

    #lightbox2-caption {
      position: fixed;
      width: 100%;
      height: auto;
      bottom: 0px;
      padding: 15px;
      color: white;
      font-size: 1.5rem;
      font-family: $fontFamily;
      font-style: normal;
      text-align: left;
      z-index: 30;
    }

    #lightbox2-caption:empty::before {
      display: none;
    }

    #lightbox2-caption::before {
      z-index: -1;
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, .7);
      $blur: 3px;
      backdrop-filter: blur($blur);
      -webkit-backdrop-filter: blur($blur);
    }

    #lightbox2-caption:empty {
      padding: 0;
    }

  }

  #lightbox2.active {
    opacity: 1;
    pointer-events: initial;

    .close.icon,
    #lightbox2-caption {
      display: initial;
    }
  }

  #lightbox2.active.lightbox-album {
    .chevron-wrapper,
    .chevron-wrapper .icon {
      display: initial;
    }
    .chevron-wrapper.hide,
    .chevron-wrapper.hide .icon {
      display: none;
    }
  }

  #lightbox2.fadeout {
    opacity: 0;
  }

  #lightbox2-lgImage{
    position: fixed;
    width: 100%;
    height: 100vh;
    opacity: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.transition{
      transition: transform 0.5s ease-in;
    }
  }

}
