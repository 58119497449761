
$iphone-overlay-width_3x: 1294 * 0.995; // slight downscale
$iphone-overlay-height_3x: 2656  * 0.995; // slight downscale
$iphone-media-width_3x: 1180; // Actual videos are 886 / 1920, meaning width is slightly wider than reported 1179
$iphone-media-height_3x: 2556; // https://blisk.io/devices/details/iphone-15-pro
// $iphone-media-margin-left_3x: 57; // original
$iphone-media-margin-left_3x: 55;
// $iphone-media-margin-top_3x: 50; // original
$iphone-media-margin-top_3x: 45;

.iphone-15-pro-flexbox-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 2rem 0 ;

    .restart-btn{
        font-size: 1.5rem;
        display: block;
        background-color: transparent;
        margin: -1.5rem auto 0px auto;
        padding: 0.5rem;
        color: gray;
        border: 0px;
    }

    .restart-btn:hover{
        cursor: pointer;
    }
}

.iphone-15-pro-flexbox-wrapper.one{
    .iphone-15-pro-wrapper{
        max-width: $DW_article;
        @include viewportArticleWidthUp{
            max-width: $DW_article;
        }
    }
}

.iphone-15-pro-flexbox-wrapper.two{
    .iphone-15-pro-wrapper{
        max-width: calc(#{$DW_article} / 2.2);
    }
}

.iphone-15-pro-flexbox-wrapper.three{
    .iphone-15-pro-wrapper{
        max-width: calc(#{$DW_article} / 3.2 );
    }
}

.iphone-15-pro-wrapper{
    position: relative;
    width: 100%;
    min-width: 200px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    aspect-ratio: calc( #{$iphone-overlay-width_3x} / #{$iphone-overlay-height_3x});

    .overlay{
        aspect-ratio: calc( #{$iphone-overlay-width_3x} / #{$iphone-overlay-height_3x});
        width: 100%;
        background-image: url("/assets/misc/iphone-15-pro-blue-adjusted-notch.webp");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
    }

    .media-padding{
        box-sizing: border-box;
        width: calc(100% * #{$iphone-media-width_3x} / #{$iphone-overlay-width_3x});
        position:absolute;
        margin-left: calc(100% * #{$iphone-media-margin-left_3x} /  #{$iphone-overlay-width_3x});
        margin-top: calc(100% * #{$iphone-media-margin-top_3x} / #{$iphone-overlay-width_3x});

        img, video{
            width: 100%;
        }
    }
}