body.post {
  //Reflections

  #back-button {
    position: fixed;
    top: 3em;
    display: none;
    @include viewport900Up {
      display: initial;
      top: calc((100vw - #{$DW_article}) / 20 + 20px);
      left: calc((100vw - #{$DW_article}) / 20);
    }
    width: 40px;
    height: 40px;
    .icon {
      font-size: 40px;
    }
    color: $orange;
    z-index: 10;
    opacity: 0;
  }

  #back-button:hover {
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }

  #back-button.enabled {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  #back-button.fadeOut {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  section.landing-image,
  .landing-image img {
    min-height: 150px;
    max-height: 70vh;
    width: 100%;
  }

  @keyframes slide-up-fade-in {
    0% {
      opacity: 0;
      transform: translateY(100vh);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  section.landing-image {
    animation: 0.5s ease-out slide-up-fade-in;
  }

  .landing-image img {
    object-fit: cover;
    object-position: 50% 50%;
  }

  .landing-image img.alignTop{
    object-position: 50% 20%;
  }

  .landing-image {
    div.title {
      @include viewport1200Up {
        padding: 4rem 4rem 4rem 0;
        max-width: $DW_article;
      }

      padding: 4rem 5vw;
      font-family: Lato, SansSerif;
      text-align: left;
      font-size: 4.5rem;
      color: white;
      max-width: $DW_article;
      margin: 0 auto;
      align-self: flex-end;
    }

    div.title-container {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.2) 55%,
        rgba(0, 0, 0, 1) 85%,
        rgba(0, 0, 0, 1) 100%
      ); /* w3c */
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
    }
  }

  .article-wrapper {
    padding: 2rem;
    
    @include viewportArticleWidthUp{
      padding-left: calc(50vw - #{$DW_article/2});
      padding-right: calc(50vw - #{$DW_article/2});
    }

    p {
      margin-top: 1.8rem;
    }

    ul,
    ol {
      padding-left: 3rem;
    }

    h1,
    h2,
    h3,
    h4,
    h1 em,
    h2 em,
    h3 em,
    h4 em {
      font-family: Lato, SansSerif;
    }

    h1,
    h1 em {
      font-size: 3.5rem;
      font-weight: 700;
      line-height: 5rem;
      padding-top: 5rem;
    }

    h2,
    h2 em {
      font-size: 2.9rem;
      font-weight: 400;
      line-height: 4rem;
      padding-top: 4rem;
    }

    h3,
    h3 em {
      font-size: 2.5rem;
      font-weight: 400;
      color: #555555;
      line-height: 3rem;
      padding-top: 3rem;
    }

    h4,
    h4 em {
      font-size: 2rem;
      font-weight: 400;
      color: #555555;
      text-decoration: underline;
      line-height: 3rem;
    }

    strong{
      font-weight:700;
    }

    // IMG sizing classes

    // Med
    .med {
      text-align: center;
    }
    .med img {
      max-width: 50%;
    }

    // No-Fit: show @ default size, without scaling up

    .interactive-website {
      margin-left: 0;
    }

    blockquote {
      border-left: 0.3rem solid #c9c9c9;
      margin: 1.2rem;
      padding: 0.2rem 2rem;
      p {
        margin-top: 0;
      }
    }

    table {
      margin: 4rem auto 5rem auto;
      border-collapse: collapse;
      width: 100%;
      .text-right {
        text-align: right;
      }
      .highlight-green {
        background-color: rgba(0, 179, 65, 0.38);
      }
      th {
        border-bottom: 2px solid black;
        font-weight: 300;
        padding: 1rem;
      }
      td {
        border: 1px solid grey;
        vertical-align: top;
      }
      tr td:first-child {
        border-left: none;
      }
      tr td:last-child {
        border-right: none;
      }
      tbody tr:last-child td {
        border-bottom: none;
      }
      tr td {
        padding: 0.3rem 1rem;
      }
      border: none;
    }

    div.title {
      padding-top: 3rem;
      font-family: Lato, SansSerif;
      font-weight: 700;
      text-align: left;
      font-size: 5rem;
      color: black;
    }

    div.subtitle {
      padding-top: 1rem;
      padding-bottom: 8rem;
      font-family: Lato, SansSerif;
      font-weight: 600;
      text-align: left;
      font-size: 3.5rem;
      border-bottom: 2px solid darkgrey;
      line-height: 4rem;
      max-width: 100%;
    }

  }

  .article {
    p {
      font-weight: 300;
    }
    padding-bottom: 4rem;

    hr {
      border: 1px solid #dadada;
      margin: 3rem;
    }
  }

  .additional-articles {
    background-color: #fafafa;
    display: flex;
    width: 100%;
    padding: 3rem 0;

    .article-column {
      width: 80%;
      @include viewport900Up{
        width: 40%;
      }
      .titles h2{
        line-height: 2.3rem;
        @include multiLineEllipsis(2.3rem, 2, #fafafa)
      }
    }

    .home-column {
      width: 20%;
      text-align: center;
      justify-content: center;
    }

    .article-column.next {
      text-align: right;
      float: right;
    }

    .article-column.prev {
      display:none;
      @include viewport900Up{
        display:block;
      }
    }
    .previous-article,
    .next-article,
    .home-column {
      align-items: center;
      display: flex;
    }

    .navigation,
    .titles {
      display: inline-block;
    }

    .titles {
      padding-bottom: 0.8rem;
      border-bottom: 3px transparent solid;
      transition: all 0.3s ease-in-out;
    }
    .navigation {
      padding: 0 1rem;
    }

    h1,
    h2 {
      font-family: Lato, SansSerif;
    }
    h1 {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }
    h2 {
      font-size: 2rem;
      font-weight: 400;
    }

    a i.icon {
      font-size: 2.5rem;
      color: black;
      border: none;
    }

    a i.home.icon {
      font-size: 3.5rem;
      padding-bottom: 3.5rem;
      border-bottom: 3px transparent solid;
      transition: all 0.3s ease-in-out;
    }

    a i.home.icon:hover {
      color: $blue;
      transition: all 0.3s ease-in-out;
      border-bottom: 3px $orange solid;
    }

    a {
      display: inline;
      text-decoration: none;
      color: black;
    }

    a,
    a div i,
    i.icon {
      transition: all 0.3s ease-in-out;
    }

    a:hover i.icon,
    a:hover .navigation {
      color: $blue;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border: none;
    }

    a:hover .titles {
      color: $blue;
      border-bottom: 3px $orange solid;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    .link-wrapper {
      display: inline-block;
    }
  }
}
