.day-to-day-video {
  background-image: url("/assets/images/day-to-day/video-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 4rem auto 4rem auto;

   @include viewport360Down {
    background-image: none;
    height: auto;
  }

  .video-wrapper {

    overflow: hidden;
    margin: 4rem auto;

    //Force video inside to match video wrapper width 
    video{
      width: 100%
    }

    //Base Case
    width: 250px;
    padding-top: 76px;
  
    @include viewport360Down {
      display: none;
    }

    @include viewport600Up{
      width: 380px;
      padding-top: 117px;
    }
   
  }

  &::before {
    @include viewport360Down {
      font-family: "Palatino", "Palatino Linotype", "Georgia", Times, Serif;
      font-size: 1.8rem;
      font-style: italic;
      content: "To view this demo, please rotate your phone or switch to a larger screen.";
      height: auto;
    }
  }
}
