body:not(.index-page) {
  $cutoffWidth: 1000px;
  $DW_article: 68rem;

  .interactive-website {
    width: 100%;
    display: block;
    margin-bottom: 3rem;
    @media (min-width: #{$cutoffWidth}) {
      max-width: none;
    }

    &::before {
      content: "Interactive Website";
      text-align: center;
      padding: 4rem;
      font-family: "Palatino", "Palatino Linotype", "Georgia", Times, Serif;
      font-style: italic;
      display: block;
      font-size: 2rem;
      @media (max-width: #{$cutoffWidth}) {
        content: "Screenshot(s) below. For an interactive demo, check this out on a desktop";
        font-size: 1.8rem;
      }
    }

    // Default iframe
    iframe {
      border: none;
      @media (max-width: #{$cutoffWidth}) {
        display: none;
      }
    }

    //Only shown when the viewport is at least 1000px wide

    &.desktop iframe {
      $desktopScaleFactor: 0.5;
      width: 1920px;
      height: 1080px;
      transform: scale($desktopScaleFactor);
      transform-origin: top left;
      margin-left: calc((#{$DW_article} - 960px) * #{$desktopScaleFactor});
      margin-bottom: (-1080px * $desktopScaleFactor);
      box-sizing: content-box;
    }

    &.tablet iframe {
      width: 768px;
      height: 1024px;
      margin-left: calc((#{$DW_article} - 768px) / 2);
    }

    &.mobile iframe {
      width: 375px;
      height: 667px;
      margin-left: calc((#{$DW_article} - 375px) / 2);
    }

    div.controls {
      text-align: center;
      padding-bottom: 3rem;

      div {
        @media (max-width: #{$cutoffWidth}) {
          display: none;
        }
        padding-left: 2rem;
        padding-right: 2rem;
        text-align: center;
        display: inline-block;
        font-family: "Palatino", "Palatino Linotype", "Georgia", Times, Serif;
        i {
          font-size: 2rem;
        }
        font-size: 1.5rem;
        transition: all 0.3s ease-in-out;
        color: black;
      }

      &:hover {
        cursor: pointer;
        color: $blue;
      }
    }

    .iframe-alternate-photos img {
      max-width: 100%;

      @media (min-width: #{$cutoffWidth}) {
        display: none;
      }
      
      margin-top: 6rem;
      @media (max-width: #{$cutoffWidth}) {
      }
    }

    .iframe-alternate-photos img:first-child {
      margin-top: 0;
    }

    .iframe-alternate-photos {
      margin: 0;
      text-align: center;
      @media (min-width: #{$cutoffWidth}) {
        display: none;
      }
    }
  }
}
