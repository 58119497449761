// RESPONSIVE BREAKPOINTS

$H_landscape_phone: 414px;
$W_tiny: 320px;
$W_phone: 576px;
$W_tablet: 768px;
$W_desktop: 992px;
$ArticlePaddingLR_desktop: 5rem;

$blue: #3878ba;
$orange: #efb64a;

$DW_landing: 1060px;

$DW_article: 68rem;

//Breakpoints based on thinking at https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/

@mixin viewport360Down{
  @media (max-width: 360px){
    @content;
  }
}

@mixin viewport600Up{
  @media (min-width: 600px){
    @content;
  }
}

@mixin viewport900Up{
  @media (min-width: 900px){
    @content;
  }
}

@mixin viewport1200Up{
  @media (min-width: 1200px){
    @content;
  }
}

@mixin viewportArticleWidthUp{
  @media (min-width: #{68rem * 0.625 + 1rem}) {
    @content;
  }
}

@mixin viewportLandscape850Down{
  @media only screen and (max-width : 850px) and (max-height: 400px) {
    @content;
  }
}

// DEFAULT STYLES
* {
  text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-size: 62.5% * 0.9;
  font-size: 62.5% ;

  text-rendering: optimizeLegibility;
}

$fontFamily: "Lora", "Palatino", "Palatino Linotype", "Georgia", Times, Serif;

p,
p *,
span,
li,
div.caption,
th,
td,
strong,
em,
a
{
  font-family: $fontFamily;
  margin-top: 1.8rem;
  color: rgba(0, 0, 0, 0.8);

  font-size: 1.7rem;
  line-height: 3rem;


  @include viewportLandscape850Down{
    font-size: 1.3rem;
    line-height: 2rem;
  }

  @include viewport900Up{
    font-size: 1.9rem;
    line-height: 3.2rem;
  }


  // Hyphens article: https://medium.com/clear-left-thinking/all-you-need-to-know-about-hyphenation-in-css-2baee2d89179
  -webkit-hyphens: auto;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-chars: 6 3 3;
  -webkit-hyphenate-limit-lines: 2;
  -webkit-hyphenate-limit-last: always;
  -webkit-hyphenate-limit-zone: 8%;

  -moz-hyphens: auto;
  -moz-hyphenate-limit-chars: 6 3 3;
  -moz-hyphenate-limit-lines: 2;
  -moz-hyphenate-limit-last: always;
  -moz-hyphenate-limit-zone: 8%;

  -ms-hyphens: auto;
  -ms-hyphenate-limit-chars: 6 3 3;
  -ms-hyphenate-limit-lines: 2;
  -ms-hyphenate-limit-last: always;
  -ms-hyphenate-limit-zone: 8%;

  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
}

.text-button,
.text-button:visited,
.text-button:link,
.text-button:hover,
.text-button:active,
a i.icon,
.article a {
  color: $blue;
  text-decoration: none;
  transition: all 0.3s ease-in-out 0s;
  border-bottom: 2px transparent solid;
}

a i.icon {
  text-align: center;
}

.text-button:hover,
a i.icon:hover,
.article a:hover {
  cursor: pointer;
  border-bottom: 2px $orange solid;
}

.container-large {
  margin: 0 auto;
  width: 100%;
  max-width: $DW_landing;
}

//Helper Functions
.center {
  text-align: center;
}

.text-left {
  text-align: left;
}

sup,
sub {
  position: relative;
  vertical-align: baseline;
  font-size: 1.5rem;
}

sup {
  top: -0.4rem;
}

sub {
  top: 0.4rem;
}

.footer-spacer{
  min-height: 8rem;
}
//MISCELLANEOUS

//Vimeo Videos
iframe.video {
  
  margin-top: 4rem;
  margin-bottom: 4rem;

  //Default - 16:9 widescreen
  height: calc(min(100vw, #{$DW_article}) * 9 / 16);
  width: calc(min(100%, #{$DW_article}));

  //19.5 x 9 resolution (iphone 11 pro vertical)
  &.vertical{
    box-sizing: content-box;
    width: calc(min(50vw, #{$DW_article} * 0.5));
    height: calc(50vw * 19.5 / 9);
    max-height: calc(#{$DW_article} * 0.5 * 19.5 / 9);
    margin-left:calc((min(#{$DW_article}, 100%) - min(50vw, #{$DW_article} * 0.5)) / 2);
  }
}

//Inline Emoji - Cat and Owl <3 
img.inline.emoji {
  max-width: 1.5rem;
  height:auto;
  width:auto;

}

/* mixin for multiline */
// http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
// Required until -line-clamp:3; is available in Firefox
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: "\2026";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 2em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
